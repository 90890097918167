//////////////////////////////
/// DISABLE CONSOLE LOGS /////
//////////////////////////////
// console.log = function () {};


///////////////////
/// LAZY LOAD /////
///////////////////

$(document).on('lazyloaded', function(e) {
    // console.log('** lazyloaded is fired', e.target);  
})

$(document).on('lazybeforeunveil', function(e) {
    
    // console.log('lazyloader baby!', e.target);
    consoleLog('lazyloader', 'warning');

    var viewportWidth = $(window).innerWidth();
    // var bg = $(e.target).data('bg-mobile');
    var bg = $(e.target).data('bg-mobile');
    if (viewportWidth > 0) {
        bg = $(e.target).data('bg-mobile');
    }
    if (viewportWidth > 768) {
        bg = $(e.target).data('bg-tablet');
    }
    if (viewportWidth > 1023) {
        bg = $(e.target).data('bg-desktop');
    }
    if (viewportWidth > 1800) {
        bg = $(e.target).data('bg-super');
    } 

    if (bg) {
      // $(e.target).css('background-image', 'url(' + bg + ')');
      // TweenMax.set($($(e.target)), {backgroundImage:'url(' + bg + ')'});
      // Also if you're going to use the css wrapper it shoud be like this:
      // TweenMax.set($(e.target),   {opacity:0}); // delay: 1

      // var giveFadeInClass = function(){
        // $(e.target).addClass('fade-in');
      // }

      TweenMax.set($(e.target),   {css:{backgroundImage:'url(' + bg + ')'}, delay: 0.4}); // onComplete:giveFadeInClass

      // TweenMax.to($(e.target),    0.5,  {opacity:1, delay: 0.5}); // delay: 1
      
      // // in case of issues with opacity
      // var setOpacityTo1 = function(){
      //   console.log('%c setOpacityTo1 called', 'color: #bada55, background: #000');
      //   console.log($(e.target));
      //   TweenMax.set($(e.target),   {opacity:1});
      // }
      // TweenMax.delayedCall(6, setOpacityTo1);  

    }

}); 

///////////////////////////////////////
/// LAZY LOAD BG IMGS, set in CSS /////
///////////////////////////////////////


// lazyload curve bg images..
// https://imagekit.io/blog/lazy-loading-images-complete-guide/

document.addEventListener("DOMContentLoaded", function() {
  var lazyloadImages;   

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll(".js-lazy-bg-image");
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          // console.log(image);
          image.classList.remove("js-lazy-bg-image");
          image.classList.add("lazy-bg-image-lazyloaded");
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function(image) {
      // console.log(image);
      imageObserver.observe(image);
    });
  } else {  
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll(".js-lazy-bg-image");
    
    function lazyload () {
      if(lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
              img.src = img.dataset.src;
              img.classList.remove('js-lazy-bg-image');
              image.classList.add("lazy-bg-imagelazyloaded");
            }
        });
        if(lazyloadImages.length == 0) { 
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
});


///////////////////
/// UTILITIES /////
///////////////////

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/////////////////////////////////////////
/// ADD OUTLINES FOR KEYBOARD USERS /////
/////////////////////////////////////////
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);


///////////////////////////////////
/// console.log() replacement /////
///////////////////////////////////

var $enableCustomLog = false;
    $enableCustomLog = true;

function consoleLog(message, color='teal') {

    if (!$enableCustomLog) {return;}

     switch (color) {
         case 'success':  
              color = 'Green'
              break
         case 'info':     
                 color = '#4d87fd'  
              break;
         case 'error':   
              color = 'Red'   
              break;
         case 'warning':  
              color = 'Orange' 
              break;
         default: 
              color = color
     }

     console.log('%c'+message, 'color:'+color);
}

// consoleLog('Hello World!');
// consoleLog('Success!', 'success');
// consoleLog('Error!', 'error');
// consoleLog('Warning!', 'warning');
// consoleLog('Info...', 'info');
consoleLog('ZAPPP!!', 'warning');

var scriptInit = function(){

    // GSAP DEFAULTS
    TweenMax.defaultEase = Back.easeOut;

    /////////////////////////////////////////////////////////////////////////////////////////////
    // Toggle on grid                                                                        / //
    /////////////////////////////////////////////////////////////////////////////////////////////

    window.onkeyup = function(e) {
       var key = e.keyCode ? e.keyCode : e.which;

       if (key == 71) {
          $('html').addClass('show-grid');
       } else if(key == 76) {
          $('html').addClass('show-lines');
       } else if (key == 27) {
           $('html').removeClass('show-grid');
           $('html').removeClass('show-lines');
       }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////
    // Mobile height 100vh fix - https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ //
    /////////////////////////////////////////////////////////////////////////////////////////////

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    // We listen to the resize event
    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    ///////////////////////////////
    // LAZY LOAD Sizes in RESIZE //
    ///////////////////////////////

    var onResize = debounce(function() {

        console.log('resize triggered');
        // VARS
        var viewportWidth = $(window).innerWidth();

        /// LAZY SIZES FOR BACKGROUNDS....
        // console.log('onResize fired. viewportWidth is', viewportWidth);
        $.each($(".lazyloaded"), function(index, item) {
            var bg = $(item).data('bg-mobile');
            if (viewportWidth > 0) {
                bg = $(item).data('bg-mobile');
            }
            if (viewportWidth > 768) {
                bg = $(item).data('bg-tablet');
            }
            if (viewportWidth > 1023) {
                bg = $(item).data('bg-desktop');
            }
            if (viewportWidth > 1800) {
                bg = $(item).data('bg-super');
            }
            if (bg) {
                // console.log('lazyloader resize!', $(item), ' - onResize fired. viewportWidth is', viewportWidth);
                $(item).css('background-image', 'url(' + bg + ')');
            }
        });

    }, 250);

    window.addEventListener('resize', onResize);


    ////////////////////////////////
    // COPY TO CLIPBOARD TOGGLE.  //
    ////////////////////////////////

    // var clipboard = new Clipboard('.clipboard-btn');

    // clipboard.on('success', function(e) {
    //     console.info('Action:', e.action);
    //     console.info('Text:', e.text);
    //     console.info('Trigger:', e.trigger);

    //     e.clearSelection();

    //     $('#clipboard-btn').text('link copied');

    // });

    // clipboard.on('error', function(e) {
    //     console.error('Action:', e.action);
    //     console.error('Trigger:', e.trigger);

    //     $('#clipboard-btn').text('error!');

    // });

    ///////////////////////////
    // TESTIMONIALS TOGGLE.  //
    ///////////////////////////


    $("body .js-testimonials-tabs li button").click(function(e) {
        console.log('testimonial tab click');
        e.preventDefault();
        console.log($(this));
        console.log($(this).parent());
        $("body .js-testimonials-tabs li").removeClass('is-active');
        $(this).parent().addClass('is-active');
        var $slideToSlideTo = $(this).data('slide');
        $slideToSlideTo = ($slideToSlideTo - 1);
        $('.js-testimonials-slider').slick('slickGoTo', $slideToSlideTo);
    });


    ///////////////////
    // MENU TOGGLE.  //
    ///////////////////

    // body.menu-is-open
    $("body .js-menu-toggle").click(function(event) {
      toggleClassMenu();
    });

    var toggleClassMenu = function() {

      $('.js-menu-toggle.hamburger').toggleClass('is-active');
      $('body').toggleClass('menu-is-open');

      var $labelCopy = $('js-hamburger--label').text();
      console.log( $labelCopy );
      if($labelCopy == 'Menu') {
        $('js-hamburger--label').text('Close');
      } else {
        $('js-hamburger--label').text('Menu');        
      }

      // console.log('%c menu toggled', 'background:#000; color:#fff');
      // $("body .off-canvas-menu").addClass("off-canvas-menu--animatable");   
      // if(!$("body .off-canvas-menu").hasClass("off-canvas-menu--visible")) {       
      //   $("body .off-canvas-menu").addClass("off-canvas-menu--visible");
      //   $('body').addClass('menu-open');
      //   $('html').addClass('menu-open');
      // } else {
      //   $("body .off-canvas-menu").removeClass('off-canvas-menu--visible');
      //   $('body .off-canvas-news-list').removeClass('off-canvas-news-list--visible');
      //   $('body').removeClass('menu-open');    
      //   $('body').removeClass('news-list-open');
        
      //   var removeClassFromHTML = function(){
      //     $('html').removeClass('menu-open');  
      //     $("body .off-canvas-menu").removeClass("off-canvas-menu--animatable");
      //   }

      //   TweenMax.delayedCall(0.2, removeClassFromHTML);   
      // }   

    }


    ///////////////////////////////////////
    // ANCHOR LINKS IN OFF CANVAS        //
    ///////////////////////////////////////

    $('a[href*=\\#]').on('click', function (event) {
        if(this.pathname === window.location.pathname){
           console.log('anchor link');
           toggleClassMenu();
        }
    });


    ///////////////////
    // DEVBAR        //
    ///////////////////

    // js-toggle-grid
    // js-toggle-columns
    // js-toggle-outlines
    // js-toggle-a11y
    // js-toggle-markup

    // body.menu-is-open
    $("body .js-devbar-toggle").click(function(e) {
      e.preventDefault();
      let $toggleClass = $(this).data('toggle');
      console.log('devbar toggle' + $toggleClass);
      $('body').toggleClass($toggleClass);
      $(this).toggleClass('is-active');
    });


    // function disp( divs ) {
    //   var a = [];
    //   for ( var i = 0; i < divs.length; i++ ) {
    //     a.push( divs[ i ].data('toggle') );
    //   }
    //   return a;
    // }

    $("body .js-devbar-toggle-all").click(function(e) {
      console.log('ALL devbar toggle');
      e.preventDefault();
      // let $allToggleClasses = $('.js-devbar-toggle').data('toggle');
      // var $all = $( ".js-devbar-toggle" );
      var $a = 'all';
      var $all = $( ".js-devbar-toggle" ).each(function( index ) {
        $a = $a + ' ' + $( this ).data('toggle');
      });
      // console.log('ALL devbar toggle' + $all);
      console.log($a);
      // console.log('devbar toggle' + $toggleClass);
      if($('body').hasClass('all')) {
        $('body').removeClass($a);
        $( ".js-devbar-toggle" ).removeClass('is-active');
      } else {
        $('body').addClass($a);
        $( ".js-devbar-toggle" ).addClass('is-active');
      }
      // $(this).toggleClass('is-active');
    });


  

    
    ///////////////////
    // SMOOTH SCROLL //
    ///////////////////

    // Select all links with hashes
    // $('a[href*="#"]')
    //   // Remove links that don't actually link to anything
    //   .not('[href="#"]')
    //   .not('[href="#news"]') // added for news in overlay
    //   .not('[href="#0"]')
    //   .click(function(event) {
    //     // On-page links
    //     if (
    //       location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
    //       && 
    //       location.hostname == this.hostname
    //     ) {
    //       // Figure out element to scroll to
    //       var target = $(this.hash);
    //       target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    //       // Does a scroll target exist?
    //       if (target.length) {
    //         // Only prevent default if animation is actually gonna happen
    //         event.preventDefault();
    //         $('html, body').animate({
    //           scrollTop: target.offset().top
    //         }, 1000, function() {
    //           // Callback after animation
    //           // Must change focus!
    //           var $target = $(target);
    //           $target.focus();
    //           if ($target.is(":focus")) { // Checking if the target was focused
    //             return false;
    //           } else {
    //             $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
    //             $target.focus(); // Set focus again
    //           };
    //         });
    //       }
    //     }
    //   });


    // OR...

    // el.scrollIntoView({
    //     behavior: 'smooth'
    // })
    





    // /**************************************************************/
    // /***   WOW                                                  ***/
    // /**************************************************************/


    var wow = new WOW(
      {
        // boxClass:     'wow',      // animated element css class (default is wow)
        // animateClass: 'animated', // animation css class (default is animated)
        // offset:       0,          // distance to the element when triggering the animation (default is 0)
        // mobile:       true,       // trigger animations on mobile devices (default is true)
        // live:         true,       // act on asynchronously loaded content (default is true)
        // callback:     function(box) {
        //   // the callback is fired every time an animation is started
        //   // the argument that is passed in is the DOM node being animated
        // },
        // scrollContainer: null,    // optional scroll container selector, otherwise use window,
        // resetAnimation: true,     // reset animation on end (default is true)
      }
    );
    wow.init();

    /////////////////////////////////
    // TESTIMONIALS SLICK SLIDER   //
    /////////////////////////////////

    var $t_slider = $('.js-testimonials-slider');

    var $t_settings = {
        // some settings
        dots: true,
        // dots: false,
        // arrows: false,
        // infinite: true,
        fade: true,
        speed: 300,
        variableWidth: false,
        // adaptiveHeight: true,
        // slidesToShow: 4,
        // slidesToScroll: 1,
        // autoplay: true,
        autoplay: false,
        // speed: 500,
        // slidesToShow: 1,
        // infinite: true,
        infinite: false,
        // fade: true,
        // autoplaySpeed: 3900,
        // vertical: true,
        // verticalSwiping: true,
        touchMove: true,
        // touchThreshold: true,
        swipe: true,
        touchThreshold: 10,
        // draggable: true,
        pauseOnHover: false,
        // rtl: true,
        arrows: false,

        // prevArrow: "<div class='a-left control-c prev slick-prev'><img src='" + js_vars.themeurl + "/svg/inline-arrow_left.svg'></div>",
        // nextArrow: "<div class='a-right control-c next slick-next'><img src='" + js_vars.themeurl + "/svg/inline-arrow_right.svg'></div>"

    };

    $t_slider.slick($t_settings);


    /////////////////////////////////
    // IFRAME LOAD ON CLICK VIDEO  //
    /////////////////////////////////
    
    // $('.js-iframe-load-on-click').click( function(event){
    //     event.preventDefault(); 
    //     // console.log('load iFrame'); 
    //     // $('.fs-option').removeClass('selected');
    //     // $('.fs-wrap').find('.fs-dropdown').removeClass('hidden');
    //     // $('.fs-wrap').addClass('fs-open');
    //     // deselect hidden fields
    //     var $url = $(this).data("src");
    //     $url = decodeURIComponent($url);
    //     $(this).html('<iframe id="iframe" src="'+$url+'" width="700" height="450"></iframe>');
    // });


};

$(document).ready(function() {


  ///////////////////
  // INIT SCRIPTS ///
  ///////////////////
  scriptInit();
  // alert('bosh');

});